@import "@app/styles/colors";
@import "@app/styles/mixins";

.root {
  position: relative;
  z-index: 10;
  height: 217px;
  background-color: $color-blue;
  color: $text-white;
  overflow: hidden;

  @include media-query {
    height: 316px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 22px 0;

  @include media-query() {
    padding: 37px 0 32px 0;

    img {
      width: 120px;
    }
  }

  :global {
    .ant-typography {
      color: $text-white;
      font-size: 10px;
      font-weight: 500;

      @include media-query() {
        font-size: 14px;
        line-height: 1.75;
      }
    }
  }
}

.logoFooter {
  margin-left: -20px;
}
