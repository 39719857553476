@import "@app/styles/variables";
@import "@app/styles/mixins";

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  background-color: #bbbbbb;
  min-width: 108px;
  height: 108px;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

@media (min-width: $screen-md) {
  .avatar {
    min-width: 172px;
    height: 172px;
    margin-right: 41px;
  }
  .content {
    :global {
      .font-13 {
        font-size: 16px !important;
      }
      .font-14 {
        font-size: 22px !important;
      }
      .font-10 {
        font-size: 14px !important;
      }
      .font-12 {
        font-size: 16px !important;
      }
      .font-7 {
        font-size: 9px !important;
      }
      .mt-1 {
        margin-top: 12px !important;
      }
    }
  }
}
