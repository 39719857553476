@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  height: 100%;
  padding: 20px 0px;
  min-height: calc(100vh - 400px);
  @include media-query {
    min-height: calc(100vh - 500px);
  }
  position: relative;
  // @media (max-width: $screen-xl) {
  //   max-width: calc(100vw * 0.91);
  //   margin: 0 auto;
  // }
}

.noData {
  font-size: 14px;
  color: $system-red;
  height: calc(100vh - 650px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-query {
    font-size: 16px;
  }
}

.pagination {
  position: absolute;
  bottom: 42px;
  text-align: center;
  width: 100%;
  @include media-query {
    bottom: 54px;
  }
}
