@import "@app/styles/mixins";
@import "@app/styles/colors";

.btnGacha {
  border-radius: 30px;
  width: 100%;
  transition: all 0.5s;
  text-align: center;
  cursor: pointer;
  padding: 5px;

  &.isDisable {
    border-color: $border-gray !important;
    background-color: $border-gray !important;
    cursor: not-allowed;

    &:hover {
      background-color: $border-gray !important;
    }
  }
}

.btnGacha1 {
  max-width: 162px;
  color: $text-black;
  border: 2px solid $system-green;

  &:hover {
    background-color: $system-green;
  }
}
.btnGacha10 {
  max-width: 162px;
  color: $text-white;
  border: none;
  background-color: $color-blue;
  &:hover {
    background-color: $color-blue-light;
  }
}

.btnGachaAll {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  color: $text-white;
  border: none;
  background-color: #ff4d00;
  max-width: 333px;
}

.btnPointText {
  font-size: 10px;
  font-weight: 700;
}

.btnPoint {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
}
