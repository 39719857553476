@import "@app/styles/mixins";
@import "@app/styles/variables";

.box {
  margin-top: 16px;

  @include media-query() {
    padding: 0 38px;
  }

  .btnBox {
    margin-top: 52px;
    text-align: center;

    @include media-query() {
      margin-top: 68px;
    }
  }

  :global {
    .ant-btn {
      width: 235px;
      font-size: 12px;
    }

    @include media-query() {
      .font-10 {
        font-size: 12px;
      }
      .mb-8 {
        margin-bottom: 50px !important;
      }
      .ant-btn {
        width: 326px;
        height: 52px;
        font-size: 16px;
        border-radius: 10px;
      }
      .ant-input {
        height: 47px;
      }
    }
  }
}

.successContainer {
  @include media-query() {
    img {
      margin-right: 30px;
    }
  }
}

.successBox {
  margin-top: 50px;
  font-size: 12px;
  text-align: center;
  white-space: pre-wrap;

  @include media-query() {
    margin-top: 65px;
    font-size: 13px;
  }
}
