@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.title {
  color: $color-red;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  z-index: 0;
  font-size: 20px;
  padding-inline: 8px;
  &::after {
    content: "";
    width: 100%;
    height: 9px;
    position: absolute;
    bottom: 4px;
    left: 0;
    z-index: -1;
    background-color: #fff171;
    @include media-query {
      height: 15px;
    }
  }
  @include media-query {
    font-size: 32px;
  }
}

.subTitle {
  font-size: 11px;
  color: $color-red;
  @include media-query {
    font-size: 18px;
  }
}
