@import "@app/styles/colors";
@import "@app/styles/mixins";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

.fullScreen {
  height: 100vh;
}

.icon {
  animation: spin infinite 2s linear;
  width: 45px;
  height: 45px;
  @include media-query {
    width: 61px;
    height: 61px;
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
