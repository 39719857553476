@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.noPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.arrowLeft {
  position: absolute;
  left: 10px;
  font-size: 9px;
  @include media-query() {
    font-size: 11px;
  }
}

.arrowRight {
  position: absolute;
  right: 10px;
  font-size: 9px;
  @include media-query() {
    font-size: 11px;
  }
}
