@import "variables";
@import "colors";
@import "@app/styles/mixins";

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.no-wrap-hidden {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.white-space-pre {
  white-space: pre;
}

// flex
.d-flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center !important;
}

.flex-align-end {
  display: flex;
  align-items: flex-end !important;
}

.flex-start {
  display: flex;
  align-items: flex-start !important;
}

.flex-space-between {
  display: flex;
  justify-content: space-between !important;
}

.flex-space-around {
  display: flex;
  justify-content: space-around !important;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly !important;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start !important;
}

.flex-justify-center {
  display: flex;
  justify-content: center !important;
}

.flex-justify-end {
  display: flex;
  justify-content: flex-end !important;
}

.flex-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-none {
  display: none;
}

.text-roman {
  list-style-type: upper-roman;
}

// Fonts
@for $size from 1 through 75 {
  .font-#{$size} {
    font-size: #{$size}px !important;
  }
}

// Text Aligns
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-end {
  text-align: end !important;
}

.text-left {
  text-align: left !important;
}

// Font weight
.font-weight-regular {
  font-weight: $font-weight-300 !important;
}

.font-weight-bold {
  font-weight: $font-weight-700 !important;
}

.font-weight-medium {
  font-weight: $font-weight-500 !important;
}

//Borders
.no-border {
  border: none !important;
}

// Spaces
$spaces: (
  "0": $spacing-0,
  "1": $spacing-1,
  "2": $spacing-2,
  "3": $spacing-3,
  "4": $spacing-4,
  "5": $spacing-5,
  "6": $spacing-6,
  "7": $spacing-7,
  "8": $spacing-8,
  "9": $spacing-9,
  "10": $spacing-10,
  "11": $spacing-11,
  "12": $spacing-12,
  "13": $spacing-13,
  "14": $spacing-14,
  "15": $spacing-15,
  "25": $spacing-25,
  "auto": "auto",
);

@for $gap from 1 through 75 {
  .gap-#{$gap} {
    gap: #{$gap}px !important;
  }
}

// line height
$line-height-list: 18, 20, 21, 22, 24, 32;
@each $height in $line-height-list {
  .line-height-#{$height} {
    line-height: #{$height}px !important;
  }
}

// Margins
@each $key, $value in $spaces {
  .m-#{$key} {
    margin: #{$value} !important;
  }

  .mt-#{$key} {
    margin-top: #{$value} !important;
  }

  .mr-#{$key} {
    margin-right: #{$value} !important;
  }

  .mb-#{$key} {
    margin-bottom: #{$value} !important;
  }

  .ml-#{$key} {
    margin-left: #{$value} !important;
  }

  .my-#{$key} {
    margin-top: #{$value} !important;
    margin-bottom: #{$value} !important;
  }

  .mx-#{$key} {
    margin-left: #{$value} !important;
    margin-right: #{$value} !important;
  }
}

// Paddings
@each $key, $value in $spaces {
  .p-#{$key} {
    padding: #{$value} !important;
  }

  .pt-#{$key} {
    padding-top: #{$value} !important;
  }

  .pr-#{$key} {
    padding-right: #{$value} !important;
  }

  .pb-#{$key} {
    padding-bottom: #{$value} !important;
  }

  .pl-#{$key} {
    padding-left: #{$value} !important;
  }

  .py-#{$key} {
    padding-top: #{$value} !important;
    padding-bottom: #{$value} !important;
  }

  .px-#{$key} {
    padding-left: #{$value} !important;
    padding-right: #{$value} !important;
  }
}

/* Colors */
$colors: (
  "white": $text-white,
  "black": $text-black,
  "primary": $color-blue,
);

// Text colors
@each $key, $value in $colors {
  .text-#{$key} {
    color: #{$value} !important;
  }
}

// Bg colors
@each $key, $value in $colors {
  .bg-#{$key} {
    background-color: #{$value} !important;
  }
}

// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

/* Positions */
$positions: "static", "relative", "absolute", "fixed", "sticky";

@each $position in $positions {
  .position-#{$position} {
    position: #{to-lower-case(#{$position})} !important;
  }
}

.position-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// text-decoration
.text-underline {
  text-decoration: underline !important;
}

//width
.width-fit-content {
  width: fit-content !important;
}

.full-width {
  width: 100% !important;
}

//border
.border-solid {
  border: 1px solid;
  padding: 0 8px;
}

//text-overflow
.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
}

// float
.float-left {
  float: left;
}
.float-right {
  float: right;
}

// Text break word
.break-word {
  overflow-wrap: break-word;
}

// Text anywhere
.anywhere {
  overflow-wrap: anywhere;
}

.text-green {
  color: $system-green;
}

.status-color {
  border: 1px solid $color-blue;
  color: $color-blue;
  @include media-query {
    border: 2px solid $color-blue;
  }
}

.status-selected-color {
  background-color: $color-blue;
  color: $text-white;
}

//link
.textLink {
  text-decoration: underline !important;
  text-underline-offset: 2px;
}

// width
$maxWidths: 100, 200, 400, 500, 600, 700, 800;
@each $value in $maxWidths {
  .mw-#{$value} {
    max-width: #{$value}px !important;
  }
}
