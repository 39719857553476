@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px 5px 8px;
  width: 120px;
  background-color: #ebebeb;
  border-radius: 20px;
  font-size: 15px;
  height: 25px;
  touch-action: manipulation;

  @include media-query() {
    width: 210px;
    font-size: 25px;
    padding: 7px 14px 7px 11px;
    height: 39px;
  }

  .label {
    font-size: 11px;

    @include media-query() {
      font-size: 16px;
    }
  }
  .labelError {
    color: $system-red;
  }
}
