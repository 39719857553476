.image {
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  &.hidden {
    opacity: 0;
    position: fixed;
    z-index: -10;
  }
}

.imageLoading {
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #ffffff, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: skeleton-animation 1.5s ease-in-out infinite;
}

@keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
