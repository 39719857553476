@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.icon {
  position: absolute;
  top: 28%;
  font-size: 11px;
  @include media-query() {
    font-size: 18px;
  }
}

.iconLeft {
  left: 34%;
}

.iconRight {
  left: 40%;
}
