@import "./colors";
@import "./variables";
@import "./common.module.scss";
@import "./icons.module.scss";
@import "@app/styles/mixins";

@font-face {
  font-family: "Source Han Sans JP";
  font-display: swap;
  font-weight: 300;
  src: local("Source Han Sans JP"),
    url(../assets/fonts/SourceHanSansJP.otf) format("opentype");
}
@font-face {
  font-family: "Source Han Sans JP";
  font-display: swap;
  font-weight: 400;
  src: local("Source Han Sans JP"),
    url(../assets/fonts/SourceHanSansJP.otf) format("opentype");
}
@font-face {
  font-family: "Source Han Sans JP";
  font-display: swap;
  font-weight: 500;
  src: local("Source Han Sans JP"),
    url(../assets/fonts/SourceHanSansJP.otf) format("opentype");
}
@font-face {
  font-family: "Source Han Sans JP";
  font-display: swap;
  font-weight: 700;
  src: local("Source Han Sans JP"),
    url(../assets/fonts/SourceHanSansJP.otf) format("opentype");
}
@font-face {
  font-family: "Source Han Sans JP";
  font-display: swap;
  font-weight: 900;
  src: local("Source Han Sans JP"),
    url(../assets/fonts/SourceHanSansJP.otf) format("opentype");
}

* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Source Han Sans JP", Arial, Helvetica, sans-serif;
  & > iframe {
    display: none;
  }
}

.container {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 20px;
}

ul {
  list-style-type: disc;
}

/* Force ant input number to take up all width when used inside col */
.ant-col {
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
}

//button
.ant-btn {
  padding: 0px 30px !important;
}

.ant-btn-primary {
  text-shadow: none;
  &:hover {
    color: $system-green !important;
    border-color: $system-green !important;
    background-color: unset !important;
  }
}

.ant-btn-dangerous {
  &:hover {
    color: $color-blue !important;
    border-color: $color-blue !important;
    background-color: unset !important;
  }
}

.ant-btn-default {
  &:hover {
    color: $text-white !important;
    border-color: $system-green !important;
    background-color: $system-green !important;
  }
  &.yellow {
    color: $system-yellow !important;
    border-color: $system-yellow !important;
    background-color: unset !important;
    &:hover {
      color: $text-white !important;
      border-color: $system-yellow !important;
      background-color: $system-yellow !important;
    }
  }
  &.red {
    color: $system-red !important;
    border-color: $system-red !important;
    background-color: unset !important;
    &:hover {
      color: $text-white !important;
      border-color: $system-red !important;
      background-color: $system-red !important;
    }
  }
  &.green {
    color: $system-green !important;
    border-color: $system-green !important;
    background-color: unset !important;
    &:hover {
      color: $text-white !important;
      border-color: $system-green !important;
      background-color: $system-green !important;
    }
  }
  &.gray {
    background: $bg-gray-ghost !important;
    border-color: $bg-gray-ghost !important;
    color: $text-black !important;
    &:hover {
      background: #676767 !important;
      border-color: #676767 !important;
      color: $text-white !important;
    }
  }
  &.gray-dark {
    background: $bg-gray-dark !important;
    border-color: $bg-gray-dark !important;
    color: $text-white !important;
    padding: 0 !important;
    &:hover {
      background: #d9d9d9 !important;
      border-color: #d9d9d9 !important;
      color: $text-white !important;
    }
  }
  &.btn-small {
    padding: 0px 16px !important;
    height: 26px !important;
    font-size: 12px;
    @include media-query() {
      height: 32px !important;
      font-size: 14px;
      padding: 0px 20px !important;
    }
  }
  &.btn-arrow {
    border-radius: 100%;
    width: 41px !important;
    height: 41px !important;
    border: none !important;
    padding: 0px !important;
    background-color: $system-grey;
    color: $system-red;
    &:hover {
      background-color: $system-red !important;
      color: white !important;
    }
    &:active {
      background-color: black !important;
      color: white !important;
    }
    @include media-query() {
      width: 67px !important;
      height: 67px !important;
    }
  }
  &.ant-btn-dangerous {
    color: $color-blue !important;
    border-color: $color-blue !important;
    background-color: unset !important;
    &:hover {
      color: $text-white !important;
      border-color: $color-blue !important;
      background-color: $color-blue !important;
    }
  }
}

.ant-btn[disabled] {
  border: none;
  background: #b5b5b5 !important;
  &:hover {
    background: #b5b5b5 !important;
  }
  div {
    color: $bg-input;
  }
  cursor: not-allowed;
}

.ant-btn-ghost {
  background-color: $bg-gray-ghost;
  color: $text-black;
  border-color: $bg-gray-ghost;
  &:hover {
    border-color: $text-black !important;
    color: $text-black !important;
  }
}

// input
.ant-input-affix-wrapper,
.ant-input {
  border-radius: 6px !important;
  background-color: $bg-input !important;
  border-color: $bg-input;
}

.ant-input-affix-wrapper {
  padding: 0px !important;
  > .ant-input-suffix {
    position: absolute;
    right: 10px;
    top: 30%;
    zoom: 1.1;
    svg {
      fill: #6c6c6c;
    }
  }
  > .ant-input-password-icon {
    color: #6c6c6c !important;
  }
  > .ant-input {
    padding: 5px 11px !important;
  }
}

.ant-input-password {
  > .ant-input {
    padding-right: 35px !important;
  }
}

// select
.ant-select-selector {
  border-radius: 7px !important;
  background-color: $bg-input !important;
}

//notification
.ant-notification {
  z-index: 100000;
  inset: 41px auto auto 50% !important;
}
.ant-notification-notice {
  padding: 10px $spacing-4;
}

.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
}

.ant-notification-notice-message {
  margin-bottom: 0px !important;
  padding-right: $spacing-0 !important;
  padding-left: $spacing-3 !important;
  font-size: $font-size-14 !important;
  font-weight: $font-weight-500 !important;
}

.ant-notification-notice-warning {
  background-color: #ffebea !important;
  border: 2px solid $system-red;
  border-radius: 4px !important;

  svg path {
    fill: $system-red;
  }
}

.ant-notification-notice-success {
  background-color: rgb(234, 255, 253) !important;
  border: 2px solid $system-green;
  border-radius: 2px !important;

  svg path {
    fill: $system-green;
  }
}

.ant-notification-notice {
  min-width: 300px;
  width: fit-content !important;
  .ant-notification-notice-close {
    display: none;
  }
}

.ant-notification-notice-message {
  margin-left: $spacing-8 !important;
}

// pagination
@mixin flex-inline-center {
  width: 22px;
  height: 22px;
  min-width: 22px;
  font-size: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-item,
.ant-pagination-item-link[type="button"] {
  border-radius: 50% !important;
  background-color: #d9d9d9 !important;
  @include flex-inline-center;
  align-items: center;
  a {
    font-weight: $font-weight-700;
  }
  font-family: "Source Han Sans JP";
}
.ant-pagination-item-active {
  background-color: $color-blue !important;
  a {
    color: white;
  }
}
.ant-pagination-prev,
.ant-pagination-next {
  @include flex-inline-center;
}
.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}
.ant-pagination-item-ellipsis {
  color: black !important;
  font-size: 12px;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  min-width: 22px;
  height: 22px;
  margin-right: 0;
}

.anticon {
  color: $color-blue;
}

.ant-pagination-disabled {
  .anticon {
    filter: brightness(0);
  }
}
.ant-pagination-options {
  display: none !important;
}
.ant-notification-notice-description {
  display: none;
}
.ant-pagination-jump-prev-custom-icon,
.ant-pagination-jump-next-custom-icon {
  pointer-events: none;
}
.ant-pagination-item.item-disabled {
  color: black;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

//modal
.ant-modal-content {
  border-radius: 20px !important;
}

.ant-modal-header {
  text-align: center;
  padding: 15px 0 !important;
  border-radius: 20px 20px 0 0 !important;
  background: $color-blue !important;

  .ant-modal-title {
    font-weight: 700;
    color: $text-white;
  }
}

.ant-modal-close {
  border-radius: 50%;
  top: 10px !important;
  right: 10px !important;
  padding: 5px !important;
  color: $system-red !important;
  background: $bg-white !important;
}

.ant-modal-footer {
  padding: 20px !important;
  text-align: center !important;
}

//checkbox
.ant-checkbox-wrapper {
  font-size: 12px;
  font-weight: 500;
  @include media-query {
    font-size: 15px;
  }
}

//form
.ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.ant-form-item-explain-error {
  font-size: 12px;
  line-height: 2;
}

// Typography
h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

// maintenance
.maintenance {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  position: absolute;
  text-align: center;
  left: -12px;
  width: 109%;
  height: 100%;
  font-size: 40px;
  color: white;
  top: 0px;
  @include media-query {
    top: -10px;
    font-size: 64px;
    height: 110%;
    width: 105%;
  }
}

// order-number
.order-number {
  font-size: 4px;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid;
  display: inline-block;
  position: relative;
  top: -2px;
  margin: 0 2px;
  padding: 0px 2px;
  min-width: 9px;
  @include media-query {
    font-size: 8px;
    min-width: 15px;
    padding: 0px 4px;
  }
}

//btnUpload
.btnUpload {
  border-radius: 0px;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.2) 0%,
    #d9d9d9 100%
  );
  border: 1px solid #c6c6c6;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  height: 25px;
  min-width: 96px;
  transition: all 0.5s;

  &:hover {
    color: #747474;
  }

  @include media-query {
    font-size: 14px;
    height: 31px;
    min-width: 125px;
  }
}

//drawer
.ant-drawer-body {
  @media (max-width: $screen-lg) {
    padding: 20px 0 !important;
  }
}
