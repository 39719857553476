@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.title {
  font-weight: 500;
  font-size: 13px;
  @include media-query {
    font-size: 15px;
  }
}

.btn {
  min-width: 41px;
  height: 20px;
  background: #30aea1;
  &.isRequire {
    background: $color-blue;
  }
  border-radius: 4px;
  margin-left: 10px;

  @include media-query {
    min-width: 44px;
    margin-left: 12px;
    height: 21px;
  }
}
.btnText {
  color: white;
  font-size: 10px;
  font-weight: 500;
  line-height: 0px;
}
