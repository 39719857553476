@import "@app/styles/mixins";
@import "@app/styles/colors";
@import "@app/styles/variables";

.container {
  max-width: 621px;
  margin: 72px auto 125px;
}

.logo {
  max-width: 363px;
  width: 80%;
  object-fit: cover;
}

.btnGroup {
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    max-width: 326px !important;
    width: 100%;
  }

  &Login {
    @extend .btnGroup;
    margin-top: 190px;

    @include media-query {
      margin-top: 111px;
    }
  }
}

.btn {
  width: 100%;
  height: 38px;
  font-size: $font-size-12;

  @include media-query {
    margin-top: 18px;
    height: 52px;
    font-size: $font-size-16;
  }
}

.title {
  margin-top: 65.81px;
  margin-bottom: 171px;

  @include media-query {
    margin-top: 90px;
    margin-bottom: 184px;
  }
}

.usableContent {
  font-size: $font-size-12;
  display: inline-block;
  text-align: left;

  @include media-query {
    font-size: $font-size-13;
  }
}
