@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.big {
  width: 100%;
  max-width: 104px;
  margin-bottom: 20px;
  @include media-query($breakpoint: xl) {
    max-width: 200px;
    margin-bottom: 30px;
  }
}

.medium {
  width: 100%;
  max-width: 79px;
  margin-bottom: 20px;
  @include media-query($breakpoint: xl) {
    max-width: 172px;
    margin-bottom: 30px;
  }
}

.big .cardImg {
  height: 145px;
  @include media-query($breakpoint: xl) {
    height: 279px;
  }
}

.medium .cardImg {
  height: 108px;
  @include media-query($breakpoint: xl) {
    height: 118px;
  }
}

.cardImg {
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  @include media-query($breakpoint: xl) {
    border-radius: 10px;
  }
}

@mixin zoom {
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 3px;
  right: 3px;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.zoom_medium {
  @include zoom;
}

.zoom_big {
  @include zoom;
  width: 27px;
  height: 27px;
  @include media-query($breakpoint: xl) {
    width: 42px;
    height: 42px;
    bottom: 6px;
    right: 6px;
  }
}

.zoomIcon_medium {
  color: $color-red;
  font-size: 11px;
}

.zoomIcon_big {
  color: $color-red;
  font-size: 11px;
  @include media-query($breakpoint: xl) {
    font-size: 19px;
  }
}

@mixin title {
  margin-top: 8px;
  margin-bottom: 12px;
  @include media-query($breakpoint: xl) {
    margin-top: 13px;
  }
  @include text-truncate($line: 2);
}

.title_medium {
  @include title;
  font-size: 10px !important;
}

.title_big {
  @include title;
  font-size: 11px;
  @include media-query($breakpoint: xl) {
    font-size: 14px;
  }
}
