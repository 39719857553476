$text-white: #fff;
$text-black: #000000;
$bg-white: #fff;
$bg-gray: #ebebeb;
$bg-gray-dark: #878787;
$bg-input: #e6e6e6;
$bg-checkbox: #8c8c8c;
$bg-gray-ghost: #e1e1e1;
$bg-main-gray: #f9f9f9;

$border-gray: #d3d3d3;

$color-blue: #0e6eb8;
$color-blue-light: #6d9bbe;

$color-red: #d44b44;
$system-red: rgba(212, 75, 68, 1);
$system-red-light: rgba(243, 145, 140, 1);

$system-green: rgba(48, 174, 161, 1);
$system-green-light: rgba(129, 218, 209, 1);

$system-yellow: rgba(223, 202, 54, 1);
$system-yellow-light: rgba(238, 229, 169, 1);

$system-grey: #e1e1e1;
$system-grey-dark: #676767;

$gray: #dadada;

$card-vip-border: linear-gradient(
  rgba(255, 221, 235, 1),
  rgba(255, 133, 184, 1),
  rgba(244, 116, 255, 1),
  rgba(220, 231, 255, 1),
  rgba(108, 255, 246, 1),
  rgba(255, 249, 109, 1),
  rgba(255, 254, 193, 1)
);

$card-sliver-border: linear-gradient(
  rgba(243, 243, 243, 1),
  rgba(181, 181, 181, 1),
  rgba(187, 187, 187, 1)
);

$card-vip-shadow: linear-gradient(-29deg, #ff6738 0%, #ff00bb 167%);

$card-vip: linear-gradient(
  #ffddeb,
  #ff85b8,
  #f474ff,
  #dce7ff,
  #6cfff6,
  #fff96d,
  #fffec1
);

$card-expired: linear-gradient(
  rgba(255, 202, 202, 1),
  rgba(239, 73, 63, 1),
  rgba(209, 3, 3, 1)
);

$card-medium: linear-gradient(#fff9e2, #efc83f, #d19703);

$card-small: linear-gradient(#dadada, #6d6d6d, #737373);
