@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.title {
  font-size: 16px;
  font-weight: 700;
  padding-right: 30px;
  @include media-query {
    font-size: 24px;
  }
  @include text-truncate($line: 2);
}

.dialog {
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  :global {
    .ant-modal-body {
      padding: 22px 17px 33px 25px !important;
      @include media-query {
        padding: 36px 28px 55px 44px !important;
      }
    }
    .ant-modal-close {
      width: 31px !important;
      height: 31px !important;
      background-color: $system-green !important;
      &:hover {
        background-color: $system-green-light !important;
      }
      @include media-query {
        width: 50px !important;
        height: 50px !important;
      }
    }
    .ant-modal-close-x {
      font-size: 12px !important;
      width: 22px !important;
      @include media-query {
        font-size: 20px !important;
        width: 40px !important;
      }
    }
    .icon-close-icon:before {
      color: white !important;
      @include media-query {
        margin-left: 3px;
      }
    }
  }
}

.statusTag {
  height: 20px !important;
  margin-right: 6px;
  min-width: 65px !important;
  @include media-query {
    height: 33px !important;
    margin-right: 10px;
    border-radius: 16.5px !important;
    min-width: 105px !important;
  }
}

.statusBox {
  display: flex;
  margin-top: 12px;
  margin-bottom: 30px;
  @include media-query {
    margin-top: 25px;
    margin-bottom: 50px;
  }
}

.cardZoomBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardCenter {
  justify-content: center !important;
}

.cardZoom {
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.35));
  user-zoom: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  z-index: 999;

  // Fix for blurry images on retina https://stackoverflow.com/questions/13347947/css-filter-on-retina-display-fuzzy-images
  img {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }

  min-height: 231px;
  @include media-query {
    min-height: 378px;
  }

  & > img {
    user-zoom: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    max-width: 166px;
    max-height: 231px;
    @include media-query {
      max-width: 272px;
      max-height: 378px;
    }
  }

  &.hidden {
    img {
      display: none;
    }
  }
}

.cardZoom img,
.cardNoImg {
  border-radius: 6px;
  object-fit: cover;
  @include media-query {
    border-radius: 12px;
  }
}

.cardBox {
  display: flex;
  justify-content: center;
}

.card {
  position: relative;
  width: 63px;
  height: 87px;
  border-radius: 3px;
  background-color: #bababa;
  margin: 19px 5px 21px;
  object-fit: cover;
  cursor: pointer;
  @include media-query {
    width: 103px;
    height: 143px;
    border-radius: 6px;
    margin: 31px 8px 35px;
  }
}

.card img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  @include media-query {
    border-radius: 6px;
  }
}

.card .noSelected {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(160, 160, 160, 1);
  mix-blend-mode: multiply;
  border-radius: 3px;
  @include media-query {
    border-radius: 6px;
  }
}

.cardImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cardNoImg {
  background-color: #bababa;
  width: 166px;
  height: 231px;
  @include media-query {
    width: 272px;
    height: 378px;
  }
}
