@import "@app/styles/mixins";
@import "@app/styles/colors";

.card-big_hit {
  background: $card-vip;
  box-shadow: 3px 2px 7px rgba(223, 242, 0, 0.25), 1px 0px 7px #f95d9e;
}

.card-almost_expired {
  background: $card-expired;
  box-shadow: 0px 0px 7px 0px #cb3100;
}

.card-medium_hit {
  background: $card-medium;
  box-shadow: 0px 0px 7px #cbb200;
}

.card-last_one {
  background: $card-vip;
  box-shadow: 3px 2px 7px rgba(223, 242, 0, 0.25), 1px 0px 7px #f95d9e;
}

.card-small_hit {
  background: $card-small;
  box-shadow: 0px 0px 7px #878787;
}

.content {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;

  @include media-query() {
    border-radius: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}
