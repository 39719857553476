@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.normal {
  @include border-gradient-animations($bg-white, "none");
}
.sliver {
  @include border-gradient-animations($card-sliver-border, rgb(70, 70, 70));
}
.gold {
  @include border-gradient-animations($card-vip-border, rgb(255, 65, 65));
}
.vip {
  @include border-gradient-animations($card-vip-border, rgb(255, 65, 65));
}

.selected {
  @include border-gradient-animations($bg-white, rgb(70, 70, 70));
}
