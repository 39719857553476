@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.container {
  margin: 0 auto !important;
  padding: 0 18px;
  max-width: 1140px;
  width: 100%;
}

.tabs {
  flex: 1;
  :global {
    @media (min-width: $screen-lg) {
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
    .ant-tabs {
      height: 100%;
    }
    .ant-tabs-nav::before {
      border-bottom: none !important;
    }
    .ant-tabs-nav-more {
      display: none !important;
    }
    .ant-tabs-nav-wrap {
      @media (min-width: $screen-lg) {
        margin-left: 32px !important;
      }
    }
    .ant-row {
      @include container;
    }
    .ant-tabs-tab {
      margin: 0;
      width: calc(100vw / 4);
      height: 25px;
      font-size: $font-size-10;
      background-color: $color-blue;
      font-weight: bold;
      color: $bg-white;
      justify-content: center;
      font-weight: 500;
      @media (min-width: $screen-lg) {
        background-color: $bg-white;
        border: 2px solid $color-blue;
        color: $color-blue;
        width: 129px;
        height: 34px;
        margin-right: 7px;
        font-size: 15px;
        border-radius: 17px;
      }
      &.ant-tabs-tab-active {
        background: $color-blue-light;
        @media (min-width: $screen-lg) {
          background: $color-blue;
        }
        & .ant-tabs-tab-btn {
          color: $bg-white;
        }
      }
    }
    .ant-tabs-ink-bar {
      height: 3px !important;
      background: $bg-white !important;
    }
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
}
