@import "@app/styles/colors";
@import "@app/styles/variables";

.content {
  max-width: 1080px;
  width: 100%;
  padding: 0 $spacing-5 !important;
  margin: 0 auto;
}

.footer {
  padding: 17px !important;
  text-align: center;
  border-top: 1px solid $gray;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  font-weight: $font-weight-700;
  font-size: $font-size-10;
}

.layout {
  min-height: 100vh;
}
