@import "@app/styles/mixins";

.root {
  :global {
    @include media-query("md", "max") {
      h2 {
        font-size: 16px;
      }
      h3 {
        font-size: 14px;
      }
      article {
        font-size: 12px;
      }
      li {
        font-size: 12px;
      }
    }
  }
}
