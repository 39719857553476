@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  background-color: $bg-main-gray;
  padding: 20px 0px 0px;
  @include media-query {
    padding-top: 18px;
  }
}

.containerBox {
  background-color: $bg-white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 444px);
  @include media-query {
    min-height: calc(100vh - 603px);
  }
}

.container {
  padding: 34px 0px 49px;
  @include media-query {
    padding: 57px 0px;
  }
}

.logo {
  width: 210px;
  @include media-query {
    width: 514px;
  }
}

.title {
  font-weight: 700;
  font-size: 13px;
  margin: 48px 0px 15px;
  @include media-query {
    font-size: 18px;
    margin: 44px 0px 25px;
  }
}

.btnBox {
  display: flex;
  justify-content: center;
  padding: 22px 0px 28px;
  flex-wrap: wrap;
  @include media-query {
    padding: 34px 0px;
  }
}

.btnBoxWidth {
  width: 333px;
  @include media-query {
    width: 523px;
  }
}

.btnBig {
  width: 100%;
  height: 38px;
  margin-bottom: 15px;
  font-size: 12px;
  @include media-query {
    height: 60px;
    margin-bottom: 18px;
    font-size: 18px;
  }
}
