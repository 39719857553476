@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.dialog {
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  .btn {
    width: 48%;
    max-width: 212px;
    height: 36px;
    font-size: 12px;
    font-weight: 700;
    @include media-query {
      height: 54px;
      font-size: 18px;
    }
  }
  :global {
    .ant-modal-title {
      display: inline-block;
      font-size: 16px;
      @include media-query {
        font-size: 24px;
      }
    }
    .ant-modal-close-x {
      line-height: 15px;
      @include media-query {
        line-height: 18px;
      }
    }
    .icon-close-icon {
      margin-left: 1px;
      font-size: 12px;
      margin-bottom: 4px;
      @include media-query {
        font-size: 18px;
      }
    }
    .ant-modal-close {
      padding: 6.5px !important;
      @include media-query {
        padding: 14.5px !important;
      }
    }
    .ant-btn.ant-btn-primary {
      div {
        @include media-query {
          top: 34%;
          padding: 0px 8px;
        }
      }
    }
    .ant-modal-footer {
      padding: 21px 21px 26px !important;
      @include media-query {
        padding: 33px 32px 39px !important;
      }
    }
    .ant-modal-body {
      padding: 19px 0px 0px;
      overflow-y: auto;
      max-height: 65vh;
      @include media-query {
        padding: 24px 0px 0px;
      }
    }
    .ant-modal-header {
      min-height: 30px;
      @include media-query {
        min-height: 70px;
        .ant-modal-title {
          line-height: 40px;
        }
      }
    }
    .icon-add-icon:before {
      cursor: pointer;
      font-size: 24px;
      @include media-query {
        font-size: 40px;
      }
    }
  }
}

.noCardAddress {
  width: 297px;
  @include media-query {
    width: 538px;
  }
}

.noCardAddressBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cardBox {
  max-height: 357px;
  overflow-y: scroll;
  @include media-query {
    max-height: 429px;
  }
}

.card {
  margin-bottom: 10px;
  @include media-query {
    margin-bottom: 22px;
  }
}

.secondPageBox {
  padding: 0px 21px;
  margin-top: 10px;
  @include media-query {
    margin-top: 20px;
    padding-left: 69px;
    padding-right: 41px;
  }
}

.secondPage {
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  padding: 16px 0px 27px;
  margin-bottom: -5px;
  @include media-query {
    border-bottom: none;
    padding: 11px 0px 0px;
    margin-bottom: 6px;
  }
}

.title {
  font-weight: 700;
  font-size: 15px;
  @include media-query {
    font-size: 20px;
  }
}

.gachaCardBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
  margin-top: 15px;
  height: 120px;
  overflow-y: scroll;
  padding-top: 5px;
  width: 277px;
  @include media-query {
    padding-top: 10px;
    width: 765px;
    gap: 10px 20px;
    height: 200px;
  }
}

.cardPC {
  @include media-query {
    width: 131px !important;
    height: 183px !important;
  }
}

.cardIcon {
  position: absolute;
  top: -7%;
  right: -6%;
  font-size: 17px;
  @include media-query {
    top: -10%;
    right: -7%;
    font-size: 35px;
  }
  :global {
    .icon-check-icon:before {
      background-color: $bg-white;
      border-radius: 100%;
      transition: all 0.5s;
    }
  }
}

.divider {
  background-color: #d3d3d3;
  height: 1px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 12px;
}

.cardTitle {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 23px;
  @include media-query {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.titleWithDots {
  white-space: nowrap;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    border-top: 1.5px dashed rgb(0, 0, 0);
    position: relative;
    vertical-align: middle;
    width: 100%;
    @include media-query {
      border-top: 2px dashed rgb(0, 0, 0);
    }
  }
}

.addressInfo {
  overflow-y: auto;
}
