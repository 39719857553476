@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  :global {
    .ant-modal-footer {
      padding-bottom: 29px !important;
      padding-top: 10px !important;
      @include media-query {
        padding-bottom: 40px !important;
      }
    }
    .ant-modal-header {
      min-height: 30px;
      @include media-query {
        min-height: 70px;
        .ant-modal-title {
          line-height: 40px;
        }
      }
    }
  }
}

.point {
  width: 158px;
  height: 33px;
  background: $bg-gray;
  border-radius: 16.5px;
  font-weight: 700;
  margin: 0 auto;
  :global {
    .icon-point-icon {
      font-size: 19px;
      @include media-query {
        font-size: 29px;
      }
    }
  }
  strong {
    font-size: 13px;
  }
  @include media-query {
    border-radius: 25px;
    padding: 6px 20px;
    font-size: 20px;
    width: 239px;
    height: 50px;
    strong {
      font-size: 20px;
    }
  }
}
