@import "@app/styles/colors";

@mixin filterColor {
  filter: brightness(0) saturate(100%) invert(34%) sepia(23%) saturate(4960%)
    hue-rotate(186deg) brightness(86%) contrast(89%);
}

.tabMobile {
  padding: 20px 0;
  background-color: $bg-white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 999;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  & > .tab:nth-child(2) {
    border-right: 1px solid $bg-gray;
    border-left: 1px solid $bg-gray;
  }
  :global {
    a.ant-typography {
      color: $text-black;
    }
    a.ant-typography:focus {
      @include filterColor;
      color: #0e6eb8;
    }
  }
}
.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.active {
  color: #0e6eb8 !important;
  @include filterColor;
}
