@import "@app/styles/mixins";
@import "@app/styles/colors";

.root {
  min-height: 60vh;
  height: 100%;
}

.wrapper {
  position: relative;
  background-color: #fcfcfc;
  @include media-query {
    padding-top: 40px;
  }
}

.container {
  @include media-query {
    margin: 0 auto !important;
    padding-top: 40px;
    display: flex;
    gap: 25px;
  }

  @media only screen and (min-width: $screen-lg) and (max-width: 1200px) {
    padding: 0 20px;
    max-width: calc(100vw * 0.91);
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
    padding: 0 20px;
  }
}

.slider {
  margin-bottom: 32px;
  @include media-query {
    margin-bottom: 56px;
  }
  :global {
    .slick-list {
      .slick-track {
        display: flex;
        & > div {
          margin: 0 5px;
          @include media-query {
            margin: 0 16px;
          }
        }
        a {
          border-radius: 10px;
          overflow: hidden;
          height: 167px;
          width: 98%;
          @include media-query {
            height: 305px;
          }
        }
      }
    }
    .slick-dots {
      bottom: -7px !important;
      @include media-query {
        bottom: -17px !important;
      }
      &.slick-dots-bottom li,
      &.slick-dots-bottom button {
        background: #c0c0c0;
        width: 8px !important;
        height: 8px !important;
        border-radius: 50%;
      }
      &.slick-active,
      .slick-active button {
        background: #001adf !important;
        opacity: 0;
      }
    }
  }
}

.tabs {
  margin-bottom: 16px;
  background-color: $color-blue;
  :global {
    .ant-tabs {
      margin: 0 auto;
      @media only screen and (min-width: $screen-lg) and (max-width: 1200px) {
        width: calc(100vw * 0.91);
      }
      @media (min-width: 1200px) {
        width: 1140px;
      }
    }
    .ant-tabs-nav-wrap {
      display: block !important;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-tab {
      height: 37px;
      flex: 1;
      color: $bg-white;
      background-color: $color-blue;
      margin: 0;
      @include media-query($screen-lg) {
        height: 51px;
      }
      .ant-tabs-tab-btn {
        font-size: 12px;
        font-weight: bold;
        margin: 0 auto;
        @include media-query {
          font-size: 16px;
        }
      }
      &.ant-tabs-tab-active {
        background: $color-blue-light;
        & .ant-tabs-tab-btn {
          color: $bg-white;
        }
      }
    }
    .ant-tabs-nav-more {
      display: none !important;
    }
    .ant-tabs-ink-bar {
      height: 3px !important;
      background: $bg-white !important;
    }
  }
}

.shops {
  background-color: $bg-main-gray;
  min-height: 123px;
  padding: 8px 0;
  @include media-query {
    padding: 30px 0;
    min-height: 226px;
  }

  :global {
    .slick-track {
      min-width: 100vw;
      display: flex !important;
      justify-content: center;
      gap: 11px;
      @include media-query {
        align-items: center;
        gap: 37px;
      }

      .slick-slide {
        width: 48px !important;

        @include media-query {
          width: 168px !important;
        }

        img {
          object-fit: cover;
        }
      }
    }
  }
}

.tabFilter {
  max-width: 25%;
  width: 100%;
}

.disablePointerEvents {
  pointer-events: none !important;
}

.banner {
  width: 100%;
  height: 100%;
  max-height: 333px;
  object-fit: fill;
  -webkit-transition: unset;
  transition: unset;
  @include media-query {
    max-height: 305px;
  }
}

.pagination {
  padding-top: 20px;
  padding-bottom: 28px;

  @include media-query {
    padding: 60px 0;
  }
}
