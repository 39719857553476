@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.alert {
  display: flex;
  align-items: center;
  background-color: #ffe1e1;
  border: 1px solid $system-red;
  padding: $spacing-2;
  :global {
    .icon-alert-error {
      font-size: 14px;
      margin-right: 6px;
      @include media-query {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
  .message {
    font-size: 12px;
    font-weight: 500;
    color: $system-red;
    @include media-query {
      font-size: 14px;
    }
  }
}
