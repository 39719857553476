// Spacing
$spacing-0: 0;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-7: 28px;
$spacing-8: 32px;
$spacing-9: 36px;
$spacing-10: 40px;
$spacing-11: 44px;
$spacing-12: 48px;
$spacing-13: 52px;
$spacing-14: 56px;
$spacing-15: 60px;
$spacing-25: 100px;

// Font sizes
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-27: 27px;
$font-size-32: 32px;
$font-size-40: 40px;

// Font weight
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

// Basic setup for handling of z-layers,
// Layers can be extended if needed
// use like:
// z-index: map-get($zLayers, navigationHeader);
$zLayers: (
  navigationHeader: 500,
);

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;

// Small screen / tablet
$screen-sm: 576px;

// Medium screen / desktop
$screen-md: 768px;

// Large screen / wide desktop
$screen-lg: 992px;

// Extra large screen / full hd
$screen-xl: 1100px;

// Extra extra large screen / large desktop
$screen-xxl: 1920px;
