@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.wrapper {
  background: red;
}

.root {
  padding-top: $spacing-5;
  padding-bottom: $spacing-9;
  :global {
    .infinite-scroll-component {
      overflow: hidden !important;
    }
    .ant-col-3 {
      flex: 0 0 33% !important;
      max-width: 33% !important;
    }
    .ant-col-7 {
      flex: 0 0 14.2% !important;
      max-width: 14.2% !important;
    }
  }
  @include media-query {
    padding-top: 18px;
    padding-bottom: 63px;
  }
}

.containerBox {
  min-height: calc(100vh - 340px);
  @include media-query {
    min-height: calc(100vh - 430px);
  }
}

.container {
  background-color: white;
  max-width: 1140px;
  margin: 0px auto !important;
}

.bigCardBox {
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
  justify-content: center;
}

.mediumCardImgBox {
  display: flex;
  justify-content: center;
  @include media-query($breakpoint: xl) {
    justify-content: space-between;
  }
}

.smallCardImg {
  width: 62px;
  min-width: 62px;
  object-fit: cover;
  height: 86px;
  border-radius: 3px;
  margin-right: 6px;
  @include media-query($breakpoint: xl) {
    width: 61px;
    min-width: 61px;
    height: 85px;
    margin-right: 11px;
    border-radius: 6px;
  }
}

.cardBoxOverFlow {
  display: flex;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 37px;
  @include media-query($breakpoint: xl) {
    margin-bottom: 72px;
  }
}
