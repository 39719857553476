@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  height: 100%;
  background-color: $bg-main-gray;
  padding: 20px 0px 20px 0px !important;
  @include media-query {
    padding: 18px 0px 40px 0px !important;
  }
}

.container {
  height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  :global {
    .container {
      max-width: 669px;
    }
  }
}

.title {
  line-height: 1.2;
  font-weight: 700;
  font-size: 14px;

  @include media-query {
    font-size: 18px;
  }
}

.btn {
  max-width: 162px;
  width: 100%;
  font-size: 12px;
  margin-bottom: 40px;

  @include media-query {
    font-size: 16px;
    height: 50px;
    max-width: 214px;
    margin-bottom: 75px;
  }
}
