@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  background-color: $bg-main-gray;
  height: 100%;
  position: relative;
  padding: 10px 0 26px;
  @include media-query {
    padding: 0;
  }
  .row {
    justify-content: space-between !important;
  }
  :global {
    .ant-col-12 {
      max-width: 664px;
    }
    .ant-col-6 {
      max-width: 282px;
    }
  }
}

.rollNumber {
  border-radius: 100px;
  border: 1px solid $text-black;
  width: 100%;
  padding: 7px 23px;
  font-weight: $font-weight-700;
  max-width: 333px;
}

.main {
  margin-top: 1px;
  background-color: $bg-white;
  height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.imgBgBox {
  margin: 10px;
  position: relative;
  aspect-ratio: 355/200;
  z-index: 1;
  @include media-query {
    aspect-ratio: 642/362;
  }
  @include media-query($breakpoint: lg) {
    margin-top: 0;
  }
}

.noImage {
  width: 100%;
  height: 100%;
  background-color: $bg-gray-dark;
  z-index: -1;
  position: relative;
}

.imgBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @include media-query($breakpoint: lg) {
    margin-top: 10px;
  }
}
.notification {
  width: 167px;
  height: 29px;
  border-radius: 14.5px;
  background-color: $bg-white;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -5px;
    left: 49%;
    @include media-query {
      bottom: -6px;
    }
  }
  > div {
    width: 100%;
  }
  @include media-query {
    font-size: 15px;
    margin-bottom: 14px;
    width: 229px;
    height: 29px;
    margin-top: 56px;
  }
}

.title {
  position: relative;
  font-weight: 900;
  font-size: 24px;
  margin: 0 auto;
  max-width: 500px;

  background: linear-gradient(
    180deg,
    #ffda57 20.83%,
    #d88100 50%,
    #ffda57 85.42%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @include media-query {
    font-size: 40px;
    line-height: 45px;
    max-width: 557px;
  }

  &:after {
    content: attr(title);
    position: absolute;
    -webkit-text-stroke: 0.45em rgb(255, 255, 255);
    left: 0;
    top: 0;
    z-index: -2;
  }

  &::before {
    content: attr(title);
    position: absolute;
    -webkit-text-stroke: 0.21em rgb(2, 2, 2);
    left: 0;
    z-index: -1;
  }
}

.subTitle {
  position: relative;
  background: transparent;
  z-index: 0;
  font-weight: 900;
  font-size: 15px;
  line-height: 20px;
  width: fit-content;
  margin: 8px auto;
  color: #000000;
  @include media-query {
    margin: 16px auto;
  }
  &:after {
    content: attr(title);
    position: absolute;
    -webkit-text-stroke: 0.45em rgb(255, 255, 255);
    left: 0;
    top: 0;
    z-index: -2;
  }
}

.totalBox {
  position: absolute;
  bottom: 15px;
  right: 0;
  @include media-query {
    position: relative;
    bottom: 0px;
    display: flex;
    margin-top: 30px;
  }
}

.point {
  width: 213px;
  height: 45px;
  background: $bg-white;
  border-radius: 19px 0px 0px 0px;
  margin-left: auto;
  font-size: 20px;
  font-weight: 700;
  @include media-query {
    border-radius: 31.5px;
    font-size: 24px;
    width: 306px;
    height: 63px;
  }
}

.totalCard {
  width: 213px;
  height: 32px;
  background: #e1e1e1;
  border-radius: 0px 0px 0px 19px;
  margin-left: auto;
  font-size: 13px;
  font-weight: 700;
  @include media-query {
    border-radius: 31.5px;
    font-size: 24px;
    width: 306px;
    height: 63px;
    margin-left: 9px;
  }
}

.imageBox {
  position: absolute;
  left: -10px;
  top: 140px;
  z-index: -2;
  @include media-query {
    top: 27px;
    left: 8%;
  }
}

.image1 {
  position: absolute;
  width: 228.13px;
  height: 319.39px;
  object-fit: contain;
  left: 0;
  top: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transform: rotate(-5.6deg);
}

.image2 {
  position: absolute;
  width: 113.35px;
  height: 158.69px;
  left: 219px;
  top: -5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  transform: rotate(2.56deg);
}

.image3 {
  position: absolute;
  width: 115.63px;
  height: 159.38px;
  left: 230px;
  top: 131px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  transform: rotate(11.46deg);
}

.container {
  padding: 0 20px;
  margin: 0 auto;
  background-color: $bg-white;
  @include media-query {
    padding-bottom: 37px;
    padding: 0 12px;
  }
}

.packName {
  width: 100%;
  max-width: 430px;
  font-size: 16px;
  font-weight: 700;
  margin-right: 35px;
  @include text-truncate(5);
  @media (max-width: $screen-sm) {
    margin-bottom: 15px;
    margin-left: 10px;
  }
}

.packInfo {
  display: flex;
  flex-direction: column;
  @media (min-width: $screen-xs) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.btnGacha1 {
  width: 162px;
  margin-top: 16px;
  &.btnDisable {
    visibility: hidden;
  }
  @include media-query {
    width: 235px;
    margin-top: 0px;
  }
}

.btnGacha10 {
  @extend .btnGacha1;
  margin-left: 9px;
  @include media-query {
    margin-left: 14px;
  }
}

.btnDetails {
  max-width: 333px;
  width: 100%;
  height: 52px;
  margin-top: 14px;
  margin-bottom: 28px;
  font-size: 16px;
  @include media-query {
    max-width: 484px;
    font-size: 20px;
    height: 52px;
  }
}

.alert {
  font-size: 15px;
  color: $system-red;
  text-align: center;
  font-weight: 700;
  margin: 38px 0 46px 0;
  @include media-query {
    margin: 38px 0 56px 0;
    font-size: 20px;
  }
}

.contentBox {
  padding: 0 20px;
  @include media-query {
    display: flex;
    align-items: flex-end;
    max-width: 648px;
    height: 327px;
    margin-left: auto;
    padding-left: 0px;
  }
}

.ButtonBackPage {
  max-width: 1140px;
  margin: 0 auto;
  margin-left: 12px;
  margin-bottom: -12px;
}

.info {
  background-color: #ebebeb;
  position: relative;
  .auto {
    max-width: 1140px;
    margin: 0 auto;
    padding: 32px 20px;
    .mark {
      position: absolute;
      top: -9px;
      @include media-query {
        top: -13.5px;
      }
    }
  }
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.tabNewest {
  top: 10%;
  position: sticky;
  height: calc(100vh + 316px);
}

.tabFilter {
  position: sticky;
  top: 10%;
  height: calc(100vh + 316px);
}

.boxShawdow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 10px 0px;
}

.gachaBox {
  padding: 12px 0 10px;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $bg-white;
}

.pointStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 5px;
  @media (min-width: $screen-xs) {
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
  }
}
