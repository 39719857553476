@import "@app/styles/mixins";
@import "@app/styles/variables";

.container {
  padding-top: 10vh;
}

.box {
  margin-top: 32px;

  @include media-query() {
    margin-top: 24px;
    padding: 0 38px;
  }

  .btnBox {
    margin-top: 60px;
    text-align: center;

    @include media-query() {
      margin-top: 83px;
    }
  }
}

.successBox {
  margin-top: 79px;
  font-size: 12px;
  line-height: 24px;
  white-space: pre-wrap;
  text-align: center;

  @include media-query() {
    margin-top: 60px;
    font-size: 13px;
  }

  .btn {
    margin-top: 126px;
    @include media-query() {
      margin-top: 93px;
    }
  }
}

.box,
.successBox {
  :global {
    .ant-form-item {
      margin-bottom: 8px;
    }
    .ant-btn {
      width: 235px;
      font-size: 12px;
    }

    @include media-query() {
      .text-explain.font-12 {
        font-size: 13px;
      }
      .mt-5 {
        margin-top: 34px !important;
      }
      .ant-btn {
        width: 326px;
        height: 52px;
        font-size: 16px;
        border-radius: 10px;
      }
      .ant-input {
        height: 47px;
      }
    }
  }
}
