@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.timer {
  font-size: 32px;
  font-weight: 500;
}

.inputTime {
  border-radius: 4px;
  background-color: $bg-gray;
  padding: 0px 8px;
}
