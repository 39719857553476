@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.dialog {
  .btn {
    width: 48%;
    max-width: 212px;
    height: 36px;
    font-size: 12px;
    font-weight: 700;

    @include media-query {
      height: 54px;
      font-size: 18px;
    }
  }
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  :global {
    .ant-modal-title {
      display: inline-block;
      max-width: 180px;

      @include media-query {
        max-width: 300px;
        font-size: 20px;
      }
    }
    .ant-modal-close-x {
      line-height: 15px;
      @include media-query {
        line-height: 18px;
      }
    }
    .icon-close-icon {
      margin-left: 1px;
      font-size: 12px;
      margin-bottom: 4px;
      @include media-query {
        font-size: 18px;
      }
    }
    .ant-modal-close {
      padding: 6.5px !important;
      @include media-query {
        padding: 14.5px !important;
      }
    }
  }
}
