@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  background-color: $bg-main-gray;
  height: 100%;
  padding: 20px 0px 82px;
  @include media-query {
    padding-bottom: 124px;
  }
  position: relative;
}

.container {
  background-color: $bg-white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  padding: 18px 20px 26px;
}

.content {
  max-width: 1100px;
  margin: 0 auto;
}

.btnBox {
  position: absolute;
  width: 100%;
  justify-content: center;
  display: flex;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  padding-inline: 20px;
  @include media-query {
    bottom: 34px;
  }
}

.btn {
  width: 100%;
  max-width: 162px;

  @include media-query {
    max-width: 214px;
  }
}
