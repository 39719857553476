@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  background-color: $bg-main-gray;
  padding: 20px 0px;
  @include media-query {
    padding-top: 22px;
    padding-bottom: 25px;
  }
}

.containerBox {
  background-color: $bg-white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 309px);
  @include media-query {
    min-height: calc(100vh - 390px);
  }
}

.container {
  background-color: white;
  max-width: 1142px;
  margin: 0px auto !important;
  padding: 17px 20px 30px 22px;
  @media only screen and (min-width: 1440px) {
    padding: 21px 20px 35px 22px;
  }
}

.listShopBox {
  max-width: 747px;
  margin-top: 21px;
  @include media-query {
    margin-top: 18px;
  }
}

.searchInput {
  padding: 0 7px !important;
  height: 28px;
  background-color: $bg-input;
  @include media-query {
    padding: 0 10px !important;
    height: 37px;
  }
}

.ant-form-item {
  max-height: 51px;
  @include media-query {
    max-height: 63px;
  }
}

.shopBox,
.shopName {
  display: flex;
  align-items: center;

  :global {
    .icon-map-icon {
      font-size: 14px;
      margin-right: 8px;
      @include media-query {
        font-size: 17px;
        margin-right: 10px;
      }
    }
  }
}

.shopBox {
  margin-bottom: 10px;
  align-items: flex-start;
}

.shopImg {
  max-width: 39px;
  min-width: 39px;
  height: 39px;
  margin-right: 10px;
  border-radius: 100%;
  margin-top: 4px;
  background-color: #d9d9d9;
  object-fit: cover;
  @include media-query {
    max-width: 45px;
    min-width: 45px;
    height: 45px;
  }
}

.shopNameBox {
  height: 46px;
  padding: 0px 14px 0px 11px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: #ececec;
    cursor: pointer;
  }
  &.error {
    box-shadow: 0px 0px 4px rgb(255, 0, 0) !important;
  }
  @include media-query {
    height: 53px;
    padding: 0px 20px 0px 13px;
  }
}
