@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  height: 100%;
  background-color: $bg-main-gray;
  padding: 20px 0px 40px 0px !important;
  @include media-query {
    padding: 18px 0px 40px 0px !important;
  }
}

.container {
  background-color: rgb(255, 255, 255);
  height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  :global {
    .container {
      max-width: 669px;
    }
  }
}

.title {
  border-left: 3px solid $color-blue;
  line-height: 1.2;
  padding-left: 8px;
  font-weight: 700;
  font-size: 14px;

  @include media-query {
    font-size: 18px;
    border-left: 5px solid $color-blue;
  }
}

.list {
  font-size: 12px;
  font-weight: 500;
  li {
    padding: 5px 10px !important;
    @include media-query {
      padding: 12px 18px !important;
      font-size: 15px;
    }
  }
}

.listHead {
  background-color: $system-grey;
  border-radius: 5px 0px 0px 5px;
  min-width: 82px;
  flex-basis: 141px;
  li {
    border-bottom: 1px solid $system-grey !important;
  }
}

.listBody {
  border-radius: 0px 5px 5px 0px;
  max-width: 480px;
  width: 100%;
  li {
    border: 1px solid $system-grey !important;
  }
}

.btn {
  max-width: 162px;
  width: 100%;
  font-size: 12px;
  margin-top: 38px;
  margin-bottom: 89px;

  @include media-query {
    font-size: 16px;
    height: 50px;
    max-width: 214px;
    margin-top: 51px;
    margin-bottom: 75px;
  }
}

.imageName {
  @include text-truncate(2);
}
