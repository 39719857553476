@import "@app/styles/colors";
@import "@app/styles/variables";

.container {
  margin-top: 18vh;
  max-width: 461px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  width: 262px;
  margin: 0 auto !important;
}

.link {
  cursor: pointer;
  color: $color-blue !important;
  text-decoration: underline !important;
  font-size: $font-size-10;
  font-weight: $font-weight-500;
  float: right !important;
  margin-top: $spacing-1 !important;
}

.password {
  margin-bottom: 0px !important;
}

.btnBox {
  text-align: center;
  margin-top: 60px;
}

@media (min-width: $screen-md) {
  .btnBox {
    margin-top: 82px;
  }
  .container {
    :global {
      .ant-btn {
        .font-12 {
          font-size: 16px !important;
        }
        width: 326px !important;
        height: 52px;
        .font-14 {
          font-size: 16px !important;
        }
      }
      .btn-signUp {
        height: 81px !important;
      }
      .ant-input {
        height: 47px !important;
      }
      .mt-15 {
        margin-top: 83px !important;
      }
      .ant-input-affix-wrapper > .ant-input-suffix {
        top: 38% !important;
        right: 14px;
      }
    }
  }
}
