@import "@app/styles/mixins";
@import "@app/styles/variables";

.container {
  max-width: 537px;
  margin: 0 auto;
  padding-top: 14vh;
}

.logo {
  width: 262px;

  @include media-query() {
    width: 363px;
  }
}

.textTitle {
  margin-top: 32px;
  font-size: 16px;
  text-align: center;

  @include media-query() {
    margin-top: 14px;
    font-size: 20px;
  }
}
