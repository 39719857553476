@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  :global {
    .ant-btn.ant-btn-round.ant-btn-default.ant-btn-dangerous {
      padding: 0px 17px !important;
      margin-left: $spacing-3;
      @include media-query {
        margin-left: $spacing-4;
      }
    }
    .ant-input {
      padding: 6px 11px;
      @include media-query {
        padding: 7px 11px;
      }
    }
    .ant-col-md-4 {
      max-width: 159px;
    }
    .ant-col-md-6 {
      max-width: 238px;
    }
    .ant-col-md-14 {
      max-width: 601px;
    }
    @media (max-width: $screen-md) {
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
}
