@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.oripaContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  min-height: 137px;
  @include media-query {
    min-height: 238px;
    max-width: 357px;
  }

  :global {
    .ant-btn {
      padding: 0 10px !important;
    }
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.contentOnDetail {
  padding: 10px 15px 10px;
}

.thumbnail {
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.packPercent {
  width: 100%;
  max-width: 158px;
  @include media-query() {
    max-width: 176px;
  }
}

.packPercentOnDetail {
  max-width: 139px !important;
}

.title {
  @include text-truncate(2);
}

.soldOut {
  filter: brightness(0.4);
}

.image {
  width: 100%;
  aspect-ratio: 320/179;
  @include media-query {
    aspect-ratio: 357/200;
  }
  object-fit: cover;
}

.imageOnDetail {
  aspect-ratio: unset;
  height: 159px;
}

.noImage {
  @extend .image;
  background-color: $gray;
}

.labelNew {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 111;
  width: 47px;
  height: 47px;
  @include media-query {
    height: 53px;
    width: 53px;
  }
}

.fontDetail12 {
  font-size: 12px !important;
}

@media (min-width: $screen-md) {
  .oripaContainer {
    :global {
      .font-16 {
        font-size: 20px !important;
      }
      .font-12 {
        font-size: 14px !important;
      }
      .mt-5 {
        margin-top: 25px;
      }
    }
  }
}
