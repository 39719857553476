@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.container {
  margin: 0 auto;
  min-height: calc(100vh - 174px);
  display: flex;
  padding: 18px 0px;
  justify-content: center;
  background-color: $bg-main-gray;
}

.main {
  width: 100%;
  margin: 0 auto;
  background-color: $bg-white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
}

.header {
  position: sticky;
  width: 100%;
  z-index: 1000;
  top: 0;
  background-color: $bg-white;
  padding: 19px 0px;

  @include media-query() {
    padding: 15px 0px;
  }
}
