@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.btnRoot {
  width: fit-content;
  :global {
    .btn-small {
      .arrowLeft {
        font-size: 7px !important;
        @include media-query() {
          font-size: 9px !important;
        }
      }
    }
  }
}
