@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.labelCard {
  height: 23px;
  padding: 4px;
  background-color: $color-red;
  color: $text-white;
  display: flex;
  align-items: center;
  @include media-query() {
    height: 33px;
    padding: 8px;
  }
}

.red {
  background-color: $color-red;
}

.yellow {
  background-color: #dfca36;
}

.blue {
  background-color: #30aea1;
}

.black {
  background-color: black;
}

.white {
  background-color: white;
}

.textWhite {
  color: $text-white;
}

.textBlack {
  color: $text-black;
}

.labelTag {
  width: 5px;
  height: 16px;
  margin-right: 7px;
}
