@import "@app/styles/mixins";
@import "@app/styles/colors";

.gachaConfirm {
  :global {
    .ant-modal-header {
      @include media-query() {
        padding: 20px 0 !important;
      }
    }
  }
}

.gachaBox {
  padding: 5px 0;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-query() {
    max-height: 220px;

    :global {
      .ant-col-md-6 {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}

.gachaImg {
  margin: 0 auto;
  width: 78px;
  height: 108px;
  padding: 2px;
  border-radius: 3px;

  @include media-query() {
    width: 131px;
    height: 183px;
    border-radius: 10px;
  }
}

.label {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 700;
  white-space: pre-wrap;
  text-align: center;

  @include media-query() {
    margin-top: 20px;
    font-size: 20px;
  }
}

.exchangeBox {
  margin: 12px auto;
  width: 158px;
  text-align: center;

  @include media-query() {
    margin-top: 24px;
    width: 239px;
  }
}

.pointChip {
  width: 100%;
  height: 33px;

  @include media-query() {
    height: 50px;
  }
}
