@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  padding: 26px 0px 0px;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  height: inherit;
  flex-direction: column;
  :global {
    .icon-close-icon {
      margin-top: 1px;
    }
    .icon-close-icon:before {
      color: $bg-white;
    }
    .ant-tabs-nav-list {
      margin-left: $spacing-2;
    }
    .ant-col-md-4 {
      @include media-query {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
    .ant-row {
      padding: 0 8px !important;
      @include media-query {
        max-width: 1140px !important;
        padding: 0 4px !important;
      }
    }
    .infinite-scroll-component {
      overflow: visible !important;
    }
    .infinite-scroll-component__outerdiv {
      flex: 1;
    }
    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 20vh;
    }
  }
  @include media-query {
    padding: 20px 0px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
}

.btnBox {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 22px;
  margin-bottom: 34px;
  max-width: 400px !important;

  @include media-query() {
    max-width: 563px !important;
    margin-top: 41px;
    margin-bottom: 55px;
  }

  .btn {
    border-radius: 30px;
    font-size: 12px;
    width: 100%;

    @include media-query() {
      height: 60px;
      font-size: 18px;
    }
    &.btnLong {
      @media (max-width: 360px) {
        font-size: 10px;
        padding: 0 10px !important;
      }
    }
  }
}

.buttonBox {
  background-color: $bg-white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  padding: 16px 24px 39px;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;

  @include media-query {
    width: 278px;
    padding: 17px 24px 34px;
    border-radius: 10px 0px 0px 10px;
  }
}

.btnMain {
  padding: 20px 0px;
  background-color: $bg-main-gray;
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.15);
  @include media-query {
    background-color: $bg-white;
    box-shadow: none;
    padding: 20px 0px 40px;
  }
}
