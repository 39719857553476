@import "@app/styles/colors";
@import "@app/styles/variables";

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@mixin border-gradient-animations($color, $shadow) {
  position: relative;
  z-index: 0;
  width: 230px;
  height: 350px;
  border-radius: 20px;
  overflow: hidden;
  padding: 1rem;
  box-shadow: 0px 0px 15px $shadow;

  &:hover {
    filter: contrast(1.2);
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: $color;
    animation: rotate 3s linear infinite;
  }

  :global {
    .content {
      position: absolute;
      overflow: hidden;
      z-index: -1;
      left: 6px;
      top: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background-size: cover;
      border-radius: 16px;
      background-color: rgba(217, 217, 217, 1);
    }
  }
}

@mixin media-query($breakpoint: md, $rule: min, $subtraction: false) {
  $mq-breakpoints: (
    "xs": $screen-xs,
    "sm": $screen-sm,
    "md": $screen-md,
    "lg": $screen-lg,
    "xl": $screen-xl,
    "xxl": $screen-xxl,
  );
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

@mixin text-truncate($line: 1) {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin container {
  margin: 0 auto !important;
  padding: 0 20px;
  max-width: 1140px;
}
