@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  padding-top: 15px;
  padding-bottom: $spacing-5;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding-right: $spacing-5;
  padding-left: 22px;
}

.points {
  max-width: 545px;
  margin: 0 auto;
  margin-top: 22px;
  margin-bottom: 8px;
  .options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

.textLink {
  margin-top: 5px;
  text-align: end;
  font-size: 10px;
  text-decoration: underline;
  text-decoration-color: $color-blue;
  text-underline-offset: 2px;
  @include media-query() {
    font-size: 12px;
  }
}

.modal {
  top: 205px;
  div[class="ant-modal-header"] {
    @include media-query() {
      padding: 26px 0 20px !important;
    }
  }

  div[class="ant-modal-body"] {
    padding-bottom: 0;
  }

  div[class="ant-modal-footer"] {
    padding-bottom: 18px 21px 20px !important;

    @include media-query {
      padding: 34px 32px 64px !important;
    }
  }
}
