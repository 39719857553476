@import "@app/styles/colors";
@import "@app/styles/variables";

.status {
  width: 71px;
  height: 18px;
  background-color: $system-yellow;
  &.onSale {
    background-color: $color-blue !important;
  }
  &.soldOut {
    background-color: $system-green;
  }
  &.beauty {
    background-color: $system-red;
  }
  &.good {
    background-color: $system-yellow;
    color: $text-black;
  }
  &.scratched {
    background-color: $system-green;
  }
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 700;
  @media (min-width: $screen-md) {
    width: 107px;
    height: 27px;
    border-radius: 13.5px;
    font-size: 14px;
  }
}
