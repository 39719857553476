@import "@app/styles/mixins";
@import "@app/styles/colors";

.box {
  display: flex;
  flex-direction: row;
  height: 100%;

  &.isChecked {
    .card {
      filter: brightness(0.6);
    }
    :global {
      .icon-check-icon:before {
        color: $color-blue;
      }
    }
  }

  @include media-query() {
    flex-direction: column;
  }

  .imgCard {
    position: relative;

    .card {
      padding: 2px;
      width: 78px;
      height: 108px;
      border-radius: 3px;
      transition: all 0.5s;

      @include media-query() {
        width: 100%;
        height: 276px;
        padding: 4px;
        border-radius: 10px;
      }
    }

    .checkbox {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      width: 12px;
      height: 12px;
      line-height: 0;
      background-color: $bg-white;
      cursor: pointer;

      @include media-query() {
        width: 25px;
        height: 25px;
        font-size: 40px;
      }

      :global {
        .icon-check-icon:before {
          position: absolute;
          top: -25%;
          right: -25%;
          transition: all 0.5s;
        }
      }
    }

    .zoomCard {
      position: absolute;
      bottom: 2%;
      right: 2%;
      font-size: 11px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      text-align: center;
      cursor: pointer;

      :global {
        .icon-search-icon:before {
          color: $color-red;
        }
      }

      @include media-query() {
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 22px;
      }
    }
  }

  .cardInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 12px;
    width: 50%;

    @include media-query() {
      width: 95%;
      margin-left: 4px;
    }
  }

  .cardName {
    font-size: 10px;
    @include text-truncate(2);

    @include media-query() {
      margin-top: 15px;
      font-size: 14px;
    }
  }

  .warning {
    color: rgba(255, 0, 0, 1);
    font-style: normal;
    line-height: 16px;
    margin-top: 6px;
    @include media-query() {
      margin-bottom: -6px;
    }
  }

  .cardType {
    font-size: 10px;
    color: #999999;
  }

  .cardPoint {
    margin-top: auto;
    font-size: 10px;
    font-weight: 200;
    font-style: italic;
    text-align: right;
    color: $text-black;

    @include media-query() {
      width: 100%;
      font-size: 15px;
      font-weight: 900;
      font-style: normal;
    }
  }
}
