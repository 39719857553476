@import "@app/styles/mixins";
@import "@app/styles/colors";
@import "@app/styles/variables";

.container {
  max-width: 621px;
  margin: 72px auto 125px;

  :global {
    label[class="ant-form-item-required"] {
      font-weight: 500 !important;
      &::before {
        display: none !important;
      }
    }

    .ant-checkbox-wrapper {
      &:hover {
        .ant-checkbox-inner {
          border-color: $bg-checkbox;
        }
      }

      .ant-checkbox {
        top: 0;
        &:hover {
          .ant-checkbox-inner {
            border-color: $bg-checkbox;
          }
        }
      }

      .ant-checkbox-checked {
        &::after {
          border-color: $bg-checkbox;
        }
        .ant-checkbox-inner {
          background-color: $bg-checkbox;
          border-color: $bg-checkbox;
        }
      }

      @include media-query {
        .ant-checkbox-inner {
          width: 25px;
          height: 25px;

          &::after {
            left: 25.5%;
            width: 8px;
            height: 14px;
          }
        }
      }
    }

    .ant-form label {
      font-size: $font-size-12;
    }

    textarea[class="ant-input"] {
      height: 131px;
    }

    .ant-input {
      font-size: $font-size-13;

      &[type="password"] {
        padding-right: 35px !important;
      }

      @include media-query {
        font-size: $font-size-15;
      }
    }

    @include media-query {
      .ant-input:not(textarea) {
        height: 47px;
      }
      textarea[class="ant-input"] {
        height: 205px;
      }
    }
  }
}

.formTyping {
  max-width: 461px;
  margin: 29px auto 0;

  & > div:nth-child(n + 2) {
    margin-top: $spacing-6;
  }

  @include media-query {
    margin: 60px auto 0 !important;
    & > div:nth-child(n + 2) {
      margin-top: 34px;
    }
  }
}

.formConfirm {
  max-width: 461px;
  margin: 29px auto 0;

  & > div {
    padding: 9px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $border-gray;
    }
  }

  @include media-query {
    margin: 45px auto 0 !important;
  }
}

.logo {
  max-width: 363px;
  width: 80%;
  object-fit: cover;
}

.btnGroup {
  max-width: 670px !important;
  display: flex;
  justify-content: center;
  gap: 18px;

  &Login {
    @extend .btnGroup;
    margin-top: 190px;

    @include media-query {
      margin-top: 111px;
    }
  }

  a {
    max-width: 326px !important;
    width: 100%;
  }
}

.btn {
  max-width: 235px;
  width: 100%;
  height: 38px;
  font-size: $font-size-12;

  @include media-query {
    max-width: 326px;
    margin-top: 18px;
    height: 52px;
    font-size: $font-size-16;
  }
}

.title {
  font-size: $font-size-16;
  margin-top: 22px;
  margin-bottom: 45px;

  @include media-query {
    font-size: $font-size-20;
    margin-bottom: $spacing-11;
  }
}

.usableContent {
  font-size: $font-size-10;
  display: inline-block;
  text-align: left;

  @include media-query {
    font-size: $font-size-14;
  }
}

.term {
  font-size: 8px;
  border: 2px solid $bg-gray;
  padding: 7px;
  height: 131px;
  overflow-y: auto;
  line-height: 12px;

  h3 {
    font-size: 12px;
  }
  h2 {
    font-size: 14px;
  }

  & * p {
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: $border-gray;
    border-radius: 10px;
  }

  @include media-query {
    height: 205px;
    font-size: 10px;
    line-height: 19px;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 10px !important;
    }
  }
}
