@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.point {
  padding: 9px 11px 10px 16px;
  background-color: $bg-gray;
  border-radius: 29px;
  line-height: 1;

  @include media-query() {
    padding: 10px 14px 12px 19px;
  }
}

.btnBuy[type="button"] {
  padding: 0 !important;
  font-weight: bold;
  min-width: 92px;
  height: 30px;

  &:focus {
    border-color: $color-blue;
    background: $color-blue;
  }

  @include media-query() {
    min-width: 109px;
    height: 36px;
  }
}

.numberPoint {
  @media (max-width: 320px) {
    font-size: 12px !important;
  }
}

.pointBox {
  gap: 12px;
  @media (max-width: 320px) {
    gap: 4px;
  }
}
