@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  height: 100%;
  background-color: $bg-main-gray;
  padding: 20px 0px 132px 0px !important;
  @include media-query {
    padding: 18px 0px 202px 0px !important;
  }
}

.container {
  background-color: rgb(255, 255, 255);
  height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  :global {
    .container {
      max-width: 640px;
    }
  }
}

.btn {
  max-width: 162px;
  width: 100%;
  font-size: 12px;
  margin-top: 23px;

  @include media-query {
    font-size: 16px;
    height: 56px;
    max-width: 255px;
    margin-top: 52px;
  }
}
