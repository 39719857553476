@import "@app/styles/mixins";

.container {
  margin-top: 30vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .textHeading {
    color: #b1b1b1;
    font-size: 34px;
    font-weight: 300;
  }

  .textSecond {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 15px;
  }

  .text {
    font-size: 12px;
    white-space: pre-wrap;
  }
}
