@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;

  :global {
    .ant-btn.ant-btn-round.ant-btn-default.ant-btn-dangerous {
      padding: 0px 17px !important;
      margin-left: $spacing-3;
      @include media-query {
        margin-left: $spacing-4;
      }
    }
    .ant-btn.ant-btn-round.ant-btn-primary.ant-btn-dangerous {
      div {
        @include media-query {
          top: 35%;
        }
      }
    }
    .ant-divider-horizontal {
      margin: 16px 0;
    }
    .ant-divider {
      border-top: 1px solid #d3d3d3;
    }
    .ant-input {
      padding: 6px 11px;
      @include media-query {
        padding: 7px 11px;
      }
    }
    .ant-col-md-4 {
      max-width: 159px;
    }
    .ant-col-md-6 {
      max-width: 238px;
    }
    .ant-col-md-14 {
      max-width: 601px;
    }
  }
}

.pointBox {
  background-color: #f2f2f2;
  padding: 8px 20px 20px;
  max-width: 584px;
  margin: 0 -20px;
  @include media-query {
    border-radius: 14px;
    height: 130px;
    padding: 20px;
    margin: 0;
  }
}

.point {
  background-color: $bg-white;
  border: 1px solid #e6e6e6;
  border-radius: 26.5px;
  height: 53px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 17px;
  @include media-query {
    margin-right: $spacing-3;
  }
}

.btnSubmit {
  width: 100%;
  max-width: 523px;
  margin: 30px auto;
  @include media-query {
    height: 60px;
    span {
      font-size: 18px;
    }
  }
}

.addressBox {
  width: 100%;
  max-width: 725px;
}

.title {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    border-top: 2px dashed rgb(0, 0, 0);
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

.contentWidth {
  min-width: 98px;
  @include media-query {
    min-width: 140px;
  }
}

.btnLink {
  &:hover,
  &:active {
    color: $color-blue-light !important;
  }
}

.camera {
  width: 15px;
  @include media-query {
    width: 20px;
  }
}
