@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  background-color: $bg-main-gray;
  padding: 20px 0px 23px;
  @include media-query {
    padding: 22px 0px 25px;
  }
  :global {
    .icon-add-icon:before {
      cursor: pointer;
      font-size: 24px;
      @include media-query {
        font-size: 40px;
      }
    }
  }
}

.containerBox {
  background-color: $bg-white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 326px);
  @include media-query {
    min-height: calc(100vh - 395px);
  }
}

.container {
  background-color: white;
  max-width: 1142px;
  margin: 0px auto !important;
  padding: 11px 20px 20px 22px;
  @include media-query {
    padding: 20px 20px 38px 22px;
  }
}

.cardAddressBox {
  margin-top: 19px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @include media-query($breakpoint: xl) {
    margin-top: 31px;
    gap: 24px;
  }
}
