@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  height: 100%;
  background-color: $bg-main-gray;
  padding: 20px 0px 79px 0px !important;
  @include media-query {
    padding: 18px 0px 121px 0px !important;
  }
}

.container {
  background-color: rgb(255, 255, 255);
  height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  :global {
    .container {
      max-width: 640px;
    }
  }
}

.thumb {
  position: relative;
  margin-top: 26px;
  z-index: 0;
}

.thumbBg {
  background-color: $system-red;
  height: 114px;
  width: 100vw;
  position: absolute;
  z-index: -1;
  top: 16px;
  margin: 0 -20px;
  @include media-query {
    height: 239px;
    top: 48px;
  }
  &.thumbBg2 {
    margin: 0 -20px;
    top: 0;
    height: 100%;
  }
}

.thumbContent {
  display: inline-flex;
  & .thumbText {
    transform-origin: top left;
    transform: scale(0.6);
    margin-top: 6px;
    @media (min-width: "335px") {
      transform: scale(0.8);
    }
    @media (min-width: "375px") {
      transform: scale(1);
      margin-top: 0;
    }
  }
}

.tipBox {
  span {
    padding: 5px 15.5px;
    color: $system-red;
    border: 2px solid $system-red;
    border-radius: 50px;
    background-color: $bg-white;
    font-size: 11px;
    font-weight: bold;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: -6px;
      left: 20px;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 6px solid rgb(255, 255, 255);
      @include media-query {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid rgb(255, 255, 255);
        bottom: -9px;
        left: 28px;
      }
    }

    @include media-query {
      font-size: 16px;
      padding: 8px 21px;
    }
  }
}

.cardImg {
  width: 100%;
  max-width: 110px;
  @include media-query {
    max-width: 252px;
    height: 347px;
  }
}

.content {
  color: $system-red;
  font-size: 18px;
  font-weight: bold;
  background-color: $bg-white;
  padding: 7px 6.5px;
  display: inline-block;
  @include media-query {
    padding: 2px 8.5px;
    font-size: 36px;
  }
}

.cardBox {
  border-radius: 18px;
  background: #f9f9f9;
  width: 100%;
  max-width: 626px;
  height: 193px;
  @include media-query {
    height: 283px;
  }
  &.cardBox3 {
    height: 110px;
    padding: 20px 30px;
  }
}

.number {
  font-size: 16px;
  color: white;
  background-color: $system-red;
  border-radius: 100px;
  width: 43px;
  height: 43px;
  display: inline-flex;
  font-weight: bold;
  margin-right: 6px;
  @include media-query {
    font-size: 24px;
    width: 64px;
    height: 64px;
    margin-right: 17px;
  }
}

.boxContainer {
  border-radius: 9px;
  height: 174px;
  width: 161px;
  padding-inline: 20px;
  &.pointBox {
    background-color: #30aea1;
    padding-inline: 10px;
  }
  background-color: $system-red;

  @media (max-width: 290px) {
    article {
      &:last-child {
        font-size: 8px !important;
      }
    }
  }

  @include media-query {
    width: 314px;
    height: 244px;
  }
}
