@import "@app/styles/colors";
@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot");
  src: url("../assets/fonts/icomoon.eot") format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf") format("truetype"),
    url("../assets/fonts/icomoon.woff") format("woff"),
    url("../assets/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a {
    font-family: var(--font-family);
  }
}

//@at-root
.icon-trash-icon:before {
  content: "\e90a";
  color: $color-blue;
}

//map
.icon-map-icon:before {
  content: "\e903";
  color: #30aea1;
}

.icon-arrow-up:before {
  content: "\e90e";
}

//menu
.icon-menu-icon:before {
  content: "\e905";
  color: $color-blue;
}

//close icon
.icon-close-icon:before {
  content: "\e90c";
  color: $color-blue;
}

//point icon
.icon-point-icon .path1:before {
  content: "\e900";
  color: #dfca36;
}
.icon-point-icon .path2:before {
  content: "\e901";
  color: #fff;
  margin-left: -1em;
}

//search
.icon-search-icon:before {
  content: "\e908";
}

//location
.icon-location-icon:before {
  content: "\e902";
  color: $color-blue;
}

//phone
.icon-phone-icon:before {
  content: "\e904";
  color: #dfca36;
}

//person
.icon-person:before {
  content: "\e91b";
  color: $color-blue;
}

//email
.icon-email:before {
  content: "\e91c";
  color: $color-blue;
}

//check
.icon-check-icon:before {
  content: "\e909";
  color: #8c8c8c;
}

.icon-check-solid:before {
  content: "\e91d";
}

//checked
.icon-checked-icon:before {
  content: "\e909";
  color: $color-blue;
}

//edit
.icon-edit-icon:before {
  content: "\e90b";
  color: $color-blue;
}

//add
.icon-add-icon:before {
  content: "\e90d";
  color: #30aea1;
}

//link
.icon-link:before {
  content: "\e91e";
  color: $color-blue;
}

// alert
.icon-alert-error:before {
  content: "\e920";
  color: $color-blue;
}

//card
.icon-card:before {
  content: "\e921";
}

//bank
.icon-bank:before {
  content: "\e922";
}
