@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  background-color: $bg-main-gray;
  padding-top: $spacing-4;
  @include media-query {
    padding-bottom: $spacing-5;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  :global {
    .item {
      white-space: break-spaces;
    }
  }
}

.container {
  margin: 0 auto !important;
  padding: 0 18px;
  max-width: 1140px;
  width: 100%;
}

.hidden {
  display: none !important;
}

.info {
  width: 100%;
  background-color: #ebebeb;
  max-width: 729px;
  padding: 4px;
  border-radius: 17px;
  cursor: pointer;
  overflow: hidden;
  min-height: 190px;
}

.showInfo {
  height: 34px !important;
  min-height: 34px !important;
}

.input {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  border-radius: 13px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
}

.icon {
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  &.show {
    transform: rotate(180deg);
  }
}

.infoShop {
  margin-top: $spacing-3;
  padding: 0px 12px 23px 12px;
  @media (min-width: $screen-md) {
    margin-top: 28px;
    @include media-query {
      padding-left: 30px;
    }
  }
}

.tabs {
  margin-top: $spacing-3 !important;
  flex: 1;
  :global {
    .ant-tabs-ink-bar {
      display: none !important;
    }
    .ant-tabs {
      height: 100%;
    }
    .ant-tabs-nav-wrap,
    .ant-row {
      @include container;
    }
    .ant-tabs-tab {
      margin: 0 4px 0 0 !important;
      width: 75px;
      height: 25px;
      font-size: $font-size-10;
      border-radius: 6px 6px 0px 0px;
      @media (min-width: $screen-md) {
        width: 102px;
        height: 34px;
        font-size: 15px;
        border-radius: 10px 10px 0px 0px;
      }
      justify-content: center;
      color: black;
      font-weight: 500;
      background: $bg-gray;
      &:nth-child(1) {
        &.ant-tabs-tab-active {
          background: $color-blue;
          & .ant-tabs-tab-btn {
            color: white;
          }
        }
      }
      &:nth-child(2) {
        &.ant-tabs-tab-active {
          background: $system-green;
          & .ant-tabs-tab-btn {
            color: white;
          }
        }
      }
      &:nth-child(3) {
        &.ant-tabs-tab-active {
          background: $system-yellow;
          & .ant-tabs-tab-btn {
            color: white;
          }
        }
      }
    }
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
    .ant-tabs-content-holder {
      background-color: white !important;
    }
    .ant-tabs-nav {
      margin-bottom: 0px !important;
    }
  }
}

@media (min-width: $screen-md) {
  .tabs {
    margin-top: 31px !important;
  }
  .info {
    min-height: 288px;
    border-radius: 26px;
    padding: 5px;
  }
  .inputContent {
    margin-left: 12px !important;
  }
  .input {
    height: 40px;
    border-radius: 20px;
  }
  .showInfo {
    height: 50px !important;
    min-height: 50px !important;
  }
  .content {
    margin-top: 28px;
    @include media-query {
      padding-left: 30px;
    }
  }
  .avatar {
    min-width: 172px;
    height: 172px;
    margin-right: 41px;
  }
  .container {
    :global {
      .item {
        margin-left: 12px !important;
      }
      .font-13 {
        font-size: 16px !important;
      }
      .font-14 {
        font-size: 22px !important;
      }
      .font-10 {
        font-size: 14px !important;
      }
      .font-12 {
        font-size: 16px !important;
      }
      .font-7 {
        font-size: 9px !important;
      }
      .mt-1 {
        margin-top: 12px !important;
      }
    }
  }
}
