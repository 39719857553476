@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  height: 100%;
  background-color: $bg-main-gray;
  padding: 20px 0px 40px 0px !important;
  @include media-query {
    padding: 18px 0px 40px 0px !important;
  }
}

.container {
  background-color: rgb(255, 255, 255);
  height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.cardBox {
  position: relative;
  text-align: center;
  border-radius: 15px;
  border: 1px solid #c0c0c0;
  max-width: 310px;
  width: 100%;
  height: 142px;
  padding: 16px 0px;
  flex-direction: column;
  cursor: pointer;

  @include media-query {
    height: 178px;
  }

  &.cardBoxActive {
    border: 3px solid $color-blue;
    padding: 14px 0px;
    background-color: #ffecec;
    color: $color-blue;
    article {
      color: $color-blue;
    }
  }
}

.cardImage {
  flex: 100%;
}

.textBox {
  background-color: #f3f3f3;
  border-radius: 6px;
  margin: 0 auto;
  max-width: 642px;
  margin-top: 17px;
  font-size: 10px;
  font-weight: 500;
  padding: 9px 12px;

  @include media-query {
    margin-top: 33px;
    font-size: 13px;
  }
}

.btn {
  max-width: 162px;
  width: 100%;
  font-size: 12px;
  margin-top: 38px;
  margin-bottom: 89px;

  @include media-query {
    font-size: 16px;
    height: 50px;
    max-width: 214px;
    margin-top: 51px;
    margin-bottom: 75px;
  }
}

.title {
  max-width: 636px;
  margin-inline: auto;
}
