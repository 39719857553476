@import "@app/styles/mixins";
@import "@app/styles/colors";

@mixin filterColor {
  filter: brightness(0) saturate(100%) invert(34%) sepia(23%) saturate(4960%)
    hue-rotate(186deg) brightness(86%) contrast(89%);
}

.tabFilter {
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  height: fit-content;
  width: 100%;
  margin-bottom: 50vh;
  @media (min-width: $screen-lg) {
    height: 100vh;
  }
}
.notAllow {
  cursor: not-allowed !important;
}

.snsGroup {
  @media (max-width: $screen-lg) {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}

.buttonSns {
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: $bg-white;
  span {
    flex: 1;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  &:hover,
  &:active {
    opacity: 0.8;
    color: white !important;
  }
}

.btnLine {
  cursor: not-allowed;
  background-color: #ccc;
  img {
    width: 48px;
  }
}

.btnX {
  background-color: #000000;
  img {
    width: 28px;
  }
}

.searchInput {
  padding-top: 24px;
  padding-bottom: 35px;
}

.labels {
  padding: 30px 0 35px;
}

.searchPoint {
  padding: 30px 0 35px;
}

.status {
  padding-top: 30px;
}

.list {
  list-style: none;
}

.btnSearchPoint {
  height: 20px;
  max-width: 123px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
}

.link {
  color: #000000;
  &:hover {
    color: $color-blue !important;
  }
}

.active {
  color: $color-blue !important;
}

.content {
  border-bottom: 1px solid #d8d8d8;
}

.noBorder {
  border-bottom: none;
}

.listMove {
  display: none;
}

@media (max-width: $screen-lg) {
  .font-20 {
    font-size: 14px;
  }
  .font-14 {
    font-size: 13px;
  }
  .content {
    padding: 0px 20px;
  }

  .tabFilter {
    max-width: 100%;
    position: unset;
    margin-bottom: 0;
  }

  .buttonSns {
    height: 33px;
    width: 133px;
    span {
      font-size: 13px !important;
    }
  }

  .btnLine {
    img {
      width: 28px;
    }
  }

  .btnX {
    img {
      width: 13px;
    }
  }

  .searchInput {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .labels {
    padding-top: 12px;
    padding-bottom: 24px;
  }

  .searchPoint {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .status {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .listMove {
    display: block;
    a:hover {
      color: $color-blue !important;
      > img {
        @include filterColor;
      }
    }
  }

  .filterColor {
    @include filterColor;
  }
}
