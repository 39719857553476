@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  :global {
    .ant-modal-footer {
      padding-bottom: 29px !important;
      padding-top: 10px !important;
      .ant-btn {
        height: 36px !important;
        max-width: 140px !important;
        font-size: $spacing-3;
      }
      @include media-query {
        padding-bottom: 40px !important;
      }
    }
    .ant-modal-header {
      min-height: 30px;
      @include media-query {
        min-height: 70px;
        .ant-modal-title {
          line-height: 40px;
        }
      }
    }
  }
}

.imageUpload {
  object-fit: cover;
  border: 11px solid $bg-gray-ghost;
}
