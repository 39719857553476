@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.cardAddressBox {
  position: relative;
  width: 333px;
  min-height: 210px;
  border-radius: 9px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background-color: #f2f2f2;
  border: 2px solid #f2f2f2;
  @media only screen and (min-width: 1142px) {
    width: 538px;
    min-height: 313px;
    border-radius: 25px;
    border: 3px solid #f2f2f2;
  }
  :global {
    .icon-check-icon:before,
    .icon-checked-icon:before {
      position: absolute;
      top: 11px;
      left: 11px;
      font-size: 21px;
      margin-right: 12px;
      @media only screen and (min-width: 1142px) {
        top: 18px;
        left: 18px;
        font-size: 34px;
        margin-right: 19px;
      }
    }
    .icon-edit-icon:before,
    .icon-trash-icon:before,
    .icon-check-icon:before {
      cursor: pointer;
    }
    .icon-checked-icon:before {
      cursor: not-allowed;
    }
    .icon-edit-icon:before,
    .icon-trash-icon:before {
      font-size: 13px;
      margin-right: 9px;
      @media only screen and (min-width: 1142px) {
        font-size: 21px;
        margin-right: 15px;
      }
    }
  }
}

.cardSelected {
  background-color: $bg-white !important;
  border: 2px solid $color-blue;
  @media only screen and (min-width: 1142px) {
    border: 3px solid $color-blue;
  }
}

.iconHeader {
  position: absolute;
  top: 10px;
  right: 8px;
  @media only screen and (min-width: 1142px) {
    top: 24px;
    right: 10px;
  }
}

.cardBody {
  padding: 6px 8px 44px 44px;
  font-size: 13px;
  @media only screen and (min-width: 1142px) {
    padding: 15px 13px 71px 71px;
    font-size: 16px;
  }
}

.cardInDeleteDialog {
  width: 308px;
  min-height: auto;
  @include media-query() {
    width: 440px;
  }
}

.cardInOrderDialog {
  width: 297px;
  min-height: auto;
  @include media-query() {
    width: 538px;
  }
}

.hasOnlyContentPadding {
  padding: 19px 10px 22px 17px;
  @include media-query() {
    padding: 35px 17px 26px 24px;
  }
}

.cardTitle {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 17px;
  width: 230px;
  @media only screen and (min-width: 1142px) {
    font-size: 24px;
    margin-bottom: 22px;
    width: 370px;
  }
}

.status {
  position: absolute;
  bottom: 11px;
  right: 11px;
  height: 22px;
  width: 97px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-weight: 700;
  font-size: 12px;
  @media only screen and (min-width: 1142px) {
    height: 35px;
    width: 156px;
    bottom: 19px;
    right: 18px;
    border-radius: 17.5px;
    font-size: 15px;
  }
}

.titleWithDots {
  white-space: nowrap;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    border-top: 1.5px dashed rgb(0, 0, 0);
    position: relative;
    vertical-align: middle;
    width: 100%;
    @media only screen and (min-width: 1142px) {
      border-top: 2px dashed rgb(0, 0, 0);
    }
  }
}

.root {
  :global {
    .ant-modal-footer {
      padding: 21px 21px 26px !important;
      @include media-query {
        padding: 33px 32px 39px !important;
      }
    }
    .ant-modal-body {
      padding: 19px 11px 0px 14px;
      @include media-query {
        padding: 38px 32px 0px;
      }
    }
    .ant-modal-header {
      min-height: 30px;
      .ant-modal-title {
        font-size: 16px;
      }
      @include media-query {
        min-height: 70px;
        .ant-modal-title {
          line-height: 40px;
          font-size: 24px;
        }
      }
    }
  }
}

.title {
  font-size: 13px;
  font-weight: 700;
  width: 143px;
  text-align: center;
  @include media-query {
    font-size: 20px;
    width: 225px;
  }
}
